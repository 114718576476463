import { getTexts } from '../../utilities/translations'

export default getTexts({
  'en': {
    subheading: `Brill Place Tower`,
    heading: `The heart of King's Cross.`,
    description: `Brill Place, situated in the vibrant heart of King's Cross, an area
    rich in history and culture, yet embraced by urban regeneration and home to some of today's
    well know brands including Google, Samsung, YouTube, Facebook, Universal Music, Sony and LVMH.
    The scheme offers 68 private residental units that are just steps away from one of the best-connected
    transport hubs in Europe, King's Cross station in Zone 1.`
  },
  'zh': {
    subheading: `Brill Place Tower`,
    heading: `国王十字区的核心地带`,
    description: `Brill Place位于充满活力的国王十字区中心，是一个集历史和文化元素於一身的地方，经过市区重建及优化后已变成多间世界知名品牌，包括Google，三星，YouTube，Facebook，环球音乐，索尼和LVMH。这楼盘提供68个私人住宅单位，距离欧洲最好的交通枢纽之一，伦敦1区的King‘s Cross车站只是几步之遥。`
  }
});
