import React from 'react'
import { navigate } from 'gatsby'
import Details from 'components/Details'
import config from 'config'
import t from '../texts'
import images from '../images'
import {
  Wrapper,
  Content,
  Heading,
  StyledSlider as Slider,
  TextBg,
  Map,
  MapCover,
  CloseButton
} from './styles'

export default () => (
  <Wrapper>
    <CloseButton onClick={() => navigate('/')} />
    <Slider images={images} />
    <TextBg />
    <Content>
      <h6>{t.subheading}</h6>
      <Heading>{t.heading}</Heading>
      <p>{t.description}</p>
      <Map>
        <iframe
          title="Google Map"
          width="794"
          height="554"
          src={config.maps.URL}
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        />
        <MapCover />
      </Map>
      <Details
        title={config.places.WALKING_TITLE}
        icon="walking"
        items={config.places.WALKING}
      />
      <Details
        title={config.places.TRANSPORT_TITLE}
        icon="travel"
        items={config.places.TRANSPORT}
      />
    </Content>
  </Wrapper>
)
