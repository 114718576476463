import React from 'react'
import MediaQuery from 'react-responsive'
import PageLayout from 'components/PageLayout'
import SEO from 'components/seo'
import Desktop from './Desktop'
import Mobile from './Mobile'
import { sizes } from 'styles'

const LifestylePage = () => (
  <PageLayout restrictHeight={false}>
    <SEO title="Lifestyle" description="The heart of King's Cross. Brill Place, situated in the vibrant heart of King's Cross, an area rich in history and culture, yet embraced by urban regeneration and home to some of today's well know brands including Google, Samsung, YouTube, Facebook, Universal Music, Sony and LVMH. The scheme offers 68 private residental units that are just steps away from one of the best-connected transport hubs in Europe, King's Cross station in Zone 1." />
    <MediaQuery minWidth={sizes.TABLE_MAX_WIDTH + 1}>
      <Desktop />
    </MediaQuery>
    <MediaQuery maxWidth={sizes.TABLE_MAX_WIDTH}>
      <Mobile />
    </MediaQuery>
  </PageLayout>
)

export default LifestylePage
