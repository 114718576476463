import styled from 'styled-components'
import { colors, media } from 'styles'

const iconSize = 42;
const itemIconSize = 45;

export const Wrapper = styled.div`
  position: relative;
  margin-top: 64px;
  max-width: 500px;

  ${media.tablet`
    max-width: 100%;
  `}
`

export const Title = styled.h6`
  line-height: ${iconSize}px;

  ${media.tablet`
    margin-top: 10px;
    line-height: inherit;
  `}
`

export const Icon = styled.i.attrs(props => ({ className: `icon-${props.name}` }))`
  color: ${colors.PRIMARY};
  font-size: ${iconSize}px;
  position: absolute;
  top: 0;
  right: 26px;

  ${media.tablet`
    position: relative;
    top: initial;
    right: initial;
    font-size: 50px;
  `}
`

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  ${media.tablet`
    flex-direction: column;
  `}
`

export const ItemIcon = styled.i.attrs(props => ({ className: `icon-${props.name}` }))`
  font-size: ${itemIconSize}px;
`

export const ItemContent = styled.div`
  line-height: ${itemIconSize}px;
  flex: 1;
  padding-left: 10px;

  ${media.tablet`
    line-height: inherit;
    flex: none;
    padding-left: 0;
  `}
`

export const ItemSecondary = styled.div`
  font-size: 18px;
  line-height: ${itemIconSize}px;
  flex: 0.2;

  ${media.tablet`
    line-height: inherit;
    flex: none;
  `}
`
