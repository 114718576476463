import React from 'react'
import { Link, navigate } from 'gatsby'
import Details from 'components/Details'
import LogoImage from 'components/Images/LogoImage'
import config from 'config'
import t from '../texts'
import images from '../images'
import {
  Wrapper,
  TextBg,
  LogoWrapper,
  Heading,
  StyledSlider as Slider,
  CloseButton
} from './styles'

export default () => (
  <Wrapper>
    <CloseButton onClick={() => navigate('/')} />
    <TextBg />
    <LogoWrapper>
      <Link to="/">
        <LogoImage />
      </Link>
    </LogoWrapper>
    <h6>{t.subheading}</h6>
    <Heading>{t.heading}</Heading>
    <Slider images={images} />
    <p>{t.description}</p>
    <Details
      title={config.places.WALKING_TITLE}
      icon="walking"
      items={config.places.WALKING}
    />
    <Details
      title={config.places.TRANSPORT_TITLE}
      icon="travel"
      items={config.places.TRANSPORT}
    />
  </Wrapper>
)
