import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Icon, Title, List, Item, ItemIcon, ItemContent, ItemSecondary } from './styles'

const Details = ({ title, icon, items, ...rest }) => (
  <Wrapper {...rest}>
    <Icon name={icon} />
    <Title>{title}</Title>
    <List>
      {items.map((item, index) => (
        <Item key={index}>
          <ItemIcon name={item.icon} />
          <ItemContent>{item.content}</ItemContent>
          <ItemSecondary>{item.secondary}</ItemSecondary>
        </Item>
      ))}
    </List>
  </Wrapper>
)

Details.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default Details
