import styled from 'styled-components'
import Slider from 'components/Slider'
import { BigCloseButtonTopRight } from 'components/BigCloseButton'
import { colors, sizes } from 'styles'
import { withPrefix } from 'gatsby'

export const Wrapper = styled.div`
  position: relative;
  padding-top: 108px;
  height: 100%;
`

export const CloseButton = BigCloseButtonTopRight

export const Content = styled.div`
  width: 610px;
  padding: 20px 60px 15px 15px;
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    bottom: 0;
    background: ${colors.WHITE};
    z-index: -1;
  }
`

export const Heading = styled.h2`
  margin: 25px 0;
`

export const StyledSlider = styled(Slider)`
  position: absolute;
  top: 40px;
  left: 430px;
  right: 0;
  bottom: -${sizes.FOOTER_HEIGHT}px;
`

export const Map = styled.div`
  position: relative;
  width: 554px;
  height: 314px;
  background: ${colors.SMOKE};
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 42px;
    transform: translate(-50%, -50%);
    background: url(${withPrefix(`map@2x.png`)}) center 100%;
    background-size: 100%;
    margin-top: -24px;
  }

  iframe {
    margin-top: -120px;
    margin-left: -120px;
  }
`

export const MapCover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const TextBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 700px;
  width: 250px;
  background: url(${withPrefix(`2020@2x.png`)}) center;
  background-size: 100%;
`
